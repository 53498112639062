import React from 'react'
import Layout from 'layouts/fr'
import Template from 'templates/terms-and-conditions'

export default () => {
  return (
    <Layout p={5}>
      <Template lang="fr" content={content} />
    </Layout>
  )
}

const content = (
  <div className="template">
    <h1 className="headline">Conditions générales</h1>
    <div className="paragraph">
      <p className="text">
        Dernière mise à jour: le 8 Juillet 2017
        <br />
        Ces conditions générales ("Conditions", "Conditions générales")
        régissent votre relation avec l'application mobile ou site web Stairlin
        (le "Service") géré par Stairlin AG ("nous", "notre", et "nos").
        <br /> Veuillez lire ces Conditions attentivement avant d'utiliser notre
        application mobile ou site web Stairlin (le "Service").
        <br /> Votre accès et utilisation de ce Service signifient que vous
        acceptez les Conditions suivantes et que vous vous y conformez. Ces
        Conditions s'appliquent à tous les visiteurs et aux utilisateurs de ce
        Service.
        <br /> En utilisant le Service, vous acceptez de vous conformer aux
        Conditions et d'en assumer toutes les conséquences juridiques. Si vous
        n'acceptez pas ces Conditions, en tout ou en partie, veuillez ne pas
        utiliser le Service.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Achats</p>
      <p className="text">
        Si vous voulez acheter un produit ou service mis à disposition par le
        Service ("Achat"), vous pourrez être amenés à fournir certaines
        informations pertinentes à votre achat, y compris et sans limitation,
        votre numéro de carte crédit, la date d'expiration de votre carte de
        crédit, votre adresse de facturation, et votre adresse de livraison.
        <br /> Vous déclarez et affirmez que: (i) vous avez légalement le droit
        d'utiliser toute(s) carte(s) de crédit(s) ou autre(s) mode(s) de
        paiement(s) en relation avec tout achat; et que (ii) toutes les
        informations que vous nous fournissez sont vrais, corrects et complets.
        <br />
        En nous remettant ces renseignements, vous nous accordez le droit de
        fournir ces renseignements à des tiers en vue de faciliter l'exécution
        des Achats.
        <br />
        Nous nous réservons le droit de refuser ou d'annuler votre commande à
        tout moment pour certaines raisons, incluant sans s'y limiter: la
        disponibilité du produit ou service, les erreurs dans la description ou
        le prix du produit ou service, une erreur dans votre commande ou pour
        d'autres raisons.
        <br />
        Nous nous réservons le droit de refuser ou d'annuler votre commande si
        une fraude ou une transaction non autorisée ou illégale est suspectée.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Disponibilités, erreurs et inexactitudes</p>
      <p className="text">
        Nous mettons constamment à jour notre offre de produits et de services
        sur le Service. Les produits ou services disponibles sur notre Service
        peuvent comporter des erreurs de prix, être mal décrits, ou être
        indisponibles, et nous pouvons faire face à des retards dans la mise à
        jour des informations sur le Service et sur notre promotion publicitaire
        sur d'autres sites web.
        <br />
        Nous ne pouvons et nous ne garantissons pas l'exactitude ou l'intégrité
        de n'importe quelle information, incluant les prix, les images des
        produits, les spécifications, et les services. Nous nous réservons le
        droit de changer ou de mettre à jour les informations et de corriger les
        erreurs, inexactitudes, ou oublis à tout moment sans notification
        préalable.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Concours, loteries et promotions</p>
      <p className="text">
        N'importe quel concours, loteries ou autres promotions (communément
        appelées "Promotions") rendus disponibles à travers le Service
        pourraient être régulés par des règlements annexes à ces Conditions. Si
        vous participez à n'importe quelle Promotion, veuillez lire les
        règlements applicables ainsi que notre Charte de Confidentialité. Si les
        règlements d'une Promotion entrent en conflit avec ces Conditions, les
        règlements de la Promotion s'appliqueront.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Abonnements</p>
      <p className="text">
        Certains aspects du Service sont fournis par abonnement
        ("Abonnement(s)"). Vous serez facturé par anticipation de façon
        récurrente et périodique ("Cycle de facturation"). Les Cycles de
        facturation sont soit mensuel ou annuel, en fonction du type
        d'abonnement que vous choisissez lorsque vous achetez un Abonnement.{" "}
        <br /> À la fin de chaque Cycle de facturation, votre Abonnement
        renouvellera automatiquement selon les mêmes conditions à moins que vous
        preniez la décision de l'annuler ou si Stairlin AG décide de l'annuler.
        Vous pouvez annuler le renouvellement automatique de votre Abonnement
        par le service de gestion de compte en ligne ou en contactant le service
        à la clientèle de Stairlin AG.
        <br /> Un mode de paiement valide, y compris la carte de crédit ou
        PayPal, est requis pour traiter la demande de paiement pour votre
        Abonnement. Vous devez fournir à Stairlin AG une information de
        facturation précise et complète incluant votre nom, adresse, province,
        code postale, numéro de téléphone, et une information de mode de
        paiement valide. En soumettant cette information de paiement, vous
        autorisez automatiquement à Stairlin AG de facturer les frais engagé par
        votre Abonnement à ces instruments de paiement.
        <br />
        En cas de dysfonctionnement de la facturation automatique, Stairlin AG
        émettra une facture électronique indiquant que vous devriez procéder
        manuellement, dans un délai déterminé, avec le paiement complet
        correspondant à la cycle de facturation figurant sur la facture.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Essai gratuit</p>
      <p className="text">
        Stairlin AG peut, à sa seule discrétion, offrir un Abonnement avec un
        essai gratuit pour une période limitée ("Essai gratuit").
        <br /> Il vous sera peut-être demandé d'entrer votre information de
        facturation afin de s'inscrire pour l'Essai gratuit.
        <br /> Si vous entrez votre information de facturation en inscrivant à
        l'Essai gratuit, vous ne serez pas facturé par Stairlin AG avant
        l'échéance de l'Essai gratuit. À la conclusion de la période d'Essai
        gratuite, sauf si vous avez annulé votre Abonnement, vous serez
        automatiquement chargé les frais d'Abonnement qui s'appliquent au type
        d'Abonnement que vous avez choisi.
        <br />À tout moment et sans préavis, Stairlin AG se réserve le droit de
        (i) modifier les conditions de l'offre d'Essai gratuit, ou (ii) procéder
        a l'annulation de l'offre d'Essai gratuit.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Modification des frais</p>
      <p className="text">
        Stairlin AG, à sa seule discrétion et à tout moment, peut modifier les
        frais d'Abonnement. Tout modifications des frais d'Abonnement entreront
        en vigueur à la fin de la Cycle de facturation en cours.
        <br />
        Stairlin AG vous parviendra un préavis raisonnable de toute modification
        aux frais d'Abonnement afin de vous donnez l'opportunité de terminer
        votre Abonnement avant que les changements ne prennent effet.
        <br /> La poursuite de votre utilisation de ce Service suite à l'entrée
        en vigueur des nouveaux frais d'Abonnement constituera votre accord à
        payer les nouveaux frais d'Abonnement.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Remboursements</p>
      <p className="text">
        Sauf en cas d'obligation légale, tous les frais payés sont non
        remboursables.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Contenu publique</p>
      <p className="text">
        Notre Service vous permet de poster, lier, stocker, partager et
        autrement rendre disponible certaines informations, textes, graphiques,
        vidéos, ou autres matériels ("Contenu"). Vous êtes seul responsable du
        contenu que vous publiez en utilisant le Service, y compris de sa
        légalité, de sa fiabilité, et de sa pertinence.
        <br />
        En publiant du Contenu en utilisant le Service, vous nous accordez le
        droit et la licence d'utiliser, modifier, afficher publiquement,
        exécuter publiquement, reproduire et distribuer ce Contenu sur ou par
        l'entremise du Service. Vous conservez tous les droits sur tout Contenu
        que vous soumettez, postez ou affichez sur ou par l'entremise du Service
        et vous acceptez d'être responsable de la protection de ces droits. Vous
        acceptez que cette licence nous donne le droit de rendre votre contenu
        accessible à d'autres utilisateurs du Service, qui pourraient également
        utiliser votre Contenu sous réserve de ces Conditions.
        <br />
        Vous représentez et garantissez que : (i) le Contenu est le votre (il
        vous appartient) ou que vous avez le droit de l'utiliser et vous nous
        donnez les droits et les contrats de licence comme spécifiés dans ces
        Conditions, et (ii) le dépôt de votre Contenu sur ou par le Service
        n'enfreint pas les droits à la vie privée, les droits de publicité, les
        droits d'auteur, les droits contractuels ou n'importe quels autres
        droits de toute autre personne.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Contenu privé</p>
      <p className="text">
        Tout contenu sauvegardé sur notre plateforme qui n’est pas destiné à
        être publiquement partagé est sauvegardé de façon sécurisée et vous seul
        avez la capacité d’y accéder, de modifier ou effacer ce contenu. Le
        contenu privé peut avoir différent format comme des vidéos, notes,
        images et en général les informations sur votre clientèle.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Comptes</p>
      <p className="text">
        Lorsque vous créez un compte chez nous, vous devrez nous fournir avec
        des renseignements qui sont exactes, complètes et à jour en tout temps.
        Le non respect de cette obligation constitue une violation des
        Conditions, qui peut entraîner la fermeture immédiate de votre compte
        sur notre Service.
        <br />
        Vous êtes responsables de la sauvegarde du mot de passe que vous
        utilisez pour accéder au Service et pour toutes activités et actions qui
        pourront être faites avec votre mot de passe, que le mot de passe soit
        avec notre Service ou un service tiers.
        <br />
        Vous acceptez de ne pas divulguer votre mot de passe à une tierce
        partie. Vous devez nous informer sans délai de toute violation de
        sécurité ou utilisation non autorisée de votre compte.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Propriété intellectuelle</p>
      <p className="text">
        Le Service et son contenu original (à l'exclusion du Contenu fourni par
        les utilisateurs), ses options et fonctionnalités sont et resteront la
        propriété exclusive de Stairlin AG et de ses concédants. Le Service est
        protégé par les droits d'auteur, la marque déposée, et les autres lois
        de Switzerland ainsi que des pays étrangers. Nos marques déposées et
        notre habillage commercial ne peuvent pas être utilisés en rapport avec
        n'importe quel produit ou service sans l'autorisation écrite préalable
        de Stairlin AG.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Liens vers d'autres sites Web</p>
      <p className="text">
        Le Service peut fournir des liens vers des sites tiers ou des services
        qui ne sont pas possédées ou contrôlées par Stairlin AG.
        <br />
        Stairlin AG n'a aucun contrôle et n'assume aucune responsabilité pour le
        contenu, les politiques de confidentialité, ou les pratiques des sites
        tiers. Vous reconnaissez et acceptez également que Stairlin AG ne peut
        être tenu responsable, directement ou indirectement, de tout dommage ou
        perte causé ou présumé avoir été causé par ou en relation avec
        l'utilisation de ou toute action sur la foi de tels contenus,
        marchandises ou services disponibles sur ou par l'entremise de tels
        sites et services.
        <br />
        Nous vous conseillons de lire attentivement les conditions générales et
        politiques de confidentialité de tout site Web de tiers ou service que
        vous utilisez.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Termination</p>
      <p className="text">
        We may terminate or suspend your account immediately, without prior
        notice or liability, for any reason whatsoever, including without
        limitation if you breach the Terms.
        <br /> Upon termination, your right to use the Service will immediately
        cease. If you wish to terminate your account, you may simply discontinue
        using the Service.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Limitation Of Liability</p>
      <p className="text">
        In no event shall Stairlin AG, nor its directors, employees, partners,
        agents, suppliers, or affiliates, be liable for any indirect,
        incidental, special, consequential or punitive damages, including
        without limitation, loss of profits, data, use, goodwill, or other
        intangible losses, resulting from (i) your access to or use of or
        inability to access or use the Service; (ii) any conduct or content of
        any third party on the Service; (iii) any content obtained from the
        Service; and (iv) unauthorized access, use or alteration of your
        transmissions or content, whether based on warranty, contract, tort
        (including negligence) or any other legal theory, whether or not we have
        been informed of the possibility of such damage, and even if a remedy
        set forth herein is found to have failed of its essential purpose.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Disclaimer</p>
      <p className="text">
        Your use of the Service is at your sole risk. The Service is provided on
        an "AS IS" and "AS AVAILABLE" basis. The Service is provided without
        warranties of any kind, whether express or implied, including, but not
        limited to, implied warranties of merchantability, fitness for a
        particular purpose, non-infringement or course of performance.
        <br /> Stairlin AG its subsidiaries, affiliates, and its licensors do
        not warrant that a) the Service will function uninterrupted, secure or
        available at any particular time or location; b) any errors or defects
        will be corrected; c) the Service is free of viruses or other harmful
        components; or d) the results of using the Service will meet your
        requirements.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Governing Law</p>
      <p className="text">
        These Terms shall be governed and construed in accordance with the laws
        of Switzerland, without regard to its conflict of law provisions.
        <br /> Our failure to enforce any right or provision of these Terms will
        not be considered a waiver of those rights. If any provision of these
        Terms is held to be invalid or unenforceable by a court, the remaining
        provisions of these Terms will remain in effect. These Terms constitute
        the entire agreement between us regarding our Service, and supersede and
        replace any prior agreements we might have between us regarding the
        Service.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Changes</p>
      <p className="text">
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material we will try to provide at
        least 30 days notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole discretion.
        <br /> By continuing to access or use our Service after those revisions
        become effective, you agree to be bound by the revised terms. If you do
        not agree to the new terms, please stop using the Service.
      </p>
    </div>
    <div className="paragraph">
      <p className="text bold">Contact Us</p>
      <p className="text">
        If you have any questions about these Terms, please contact us.
      </p>
    </div>
  </div>
);
